var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { direction: _vm.$vs.rtl ? "rtl" : "ltr" } },
    [
      _vm.params.data &&
      (_vm.params.data.type === "CREDIT" || _vm.params.data.type === "DEBIT") &&
      _vm.params.data.transaction.situation === "PAID"
        ? _c(
            "div",
            [
              _vm.params.data.type === "CREDIT"
                ? _c("feather-icon", {
                    attrs: {
                      title: "Recibo",
                      icon: "PrinterIcon",
                      svgClasses:
                        "h-5 w-5 mr-4 hover:text-primary cursor-pointer",
                    },
                    on: { click: _vm.printReceipt },
                  })
                : _vm._e(),
              _c("feather-icon", {
                attrs: {
                  title: "Estorno",
                  icon: "RotateCcwIcon",
                  svgClasses: "h-5 w-5 hover:text-primary cursor-pointer",
                },
                on: { click: _vm.chargeback },
              }),
            ],
            1
          )
        : _vm.params.data
        ? _c("feather-icon", {
            attrs: {
              title: "Sem ações para esta transação!",
              icon: "SlashIcon",
              svgClasses: "h-5 w-5 hover:text-primary cursor-default",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }