<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <div v-if="params.data && (params.data.type === 'CREDIT' || params.data.type === 'DEBIT') && params.data.transaction.situation === 'PAID'">
        <feather-icon v-if="params.data.type === 'CREDIT'" title="Recibo" icon="PrinterIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="printReceipt" />
        <feather-icon title="Estorno" icon="RotateCcwIcon" svgClasses="h-5 w-5 hover:text-primary cursor-pointer" @click="chargeback" />
      </div>
      <feather-icon v-else-if="params.data" title="Sem ações para esta transação!" icon="SlashIcon" svgClasses="h-5 w-5 hover:text-primary cursor-default" />
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  computed: {
    cashier () {
      return this.$store.state.myCashier.cashier
    }
  },
  methods: {
    printReceipt () {
      if (this.cashier.status === 'OPEN') {
        this.params.printReceipt(this.params.data, this.params.node)// assim chamo a função de fora
      } else {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'O caixa está fechado.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    chargeback () {
      if (this.cashier.status === 'OPEN') {
        this.params.chargeback(this.params.data)// assim chamo a função de fora
      } else {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'O caixa está fechado.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    }
  }
}
</script>
