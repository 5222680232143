<template>
  <div id="page-my-cashier">
    <sig-popup v-model="popupCashierReport">
        <cashier-report-form />
    </sig-popup>
    <dialog-sig
      :show="popupGenerateReceiptConfirm"
      title="ALERTA"
      icon="warning"
      @action="generateReceipt(printReceiptData, printReceiptNode)"
      @cancel="popupGenerateReceiptConfirm = false"
      actionButtonText="Gerar Recibo"
      actionButtonColor="warning"
    >
      <span
        v-html="
          'Um novo recibo será criado! <br> Tem certeza que deseja emitir este recibo?'
        "
      ></span>
    </dialog-sig>

    <vs-popup
      class="popup80 noOverflow"
      :fullscreen="false"
      :title="showPdfTitle"
      :active.sync="popupShowPdf"
    >
      <object :data="srcPdf" width="100%" style="height: 600px" />
    </vs-popup>

    <!-- TRANSFERÊNCIAS -->
    <vs-popup
      class="popup40"
      :fullscreen="false"
      title="Transferência"
      :active.sync="popupTransfer"
    >
    <form-transfer @saved="popupTransfer = false, refreshGrid()" :cashier-id="cashier.id" />
      <!-- <div class="vx-row">
        <div class="vx-col w-full">
          <label class="vs-input--label">De</label>
          <v-select
            v-model="account_from"
            :reduce="(option) => option.value"
            :clearable="false"
            data-vv-as="De"
            data-vv-scope="transfer"
            v-validate.initial="'required'"
            name="account_from"
            :options="accountsFromOptions"
            placeholder="Selecione"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <label class="vs-input--label">Para</label>
          <v-select
            v-model="account_to"
            :reduce="(option) => option.value"
            :clearable="false"
            data-vv-as="Para"
            data-vv-scope="transfer"
            v-validate.initial="'required'"
            name="account_to"
            :options="accountsToOptions"
            placeholder="Selecione"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <label class="vs-input--label">Valor</label>
          <currency-input
            class="w-full vs-inputx vs-input--input normal hasValue"
            v-model="transfer_value"
            :currency="{ prefix: 'R$ ' }"
            :valueAsInteger="false"
            :distractionFree="false"
            :precision="2"
            :autoDecimalMode="true"
            :valueRange="{ min: 0 }"
            :allowNegative="false"
          />
        </div>
      </div>

      <vs-divider />
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button
              color="secondary"
              class="ml-auto"
              @click="popupTransfer = false"
              >Cancelar</vs-button
            >
            <vs-button class="ml-4" color="danger" @click="transfer"
              >Transferir</vs-button
            >
          </div>
        </div>
      </div> -->
    </vs-popup>

    <!-- FECHAMENTO DE CAIXA -->
    <vs-popup
      class="popup40"
      :fullscreen="false"
      title="Fechamento de Caixa"
      :active.sync="popupCloseCashier"
    >
      <div class="vx-row">
        <div class="vx-col w-1/5">
          <vs-icon icon="warning" size="large" color="warning"></vs-icon>
        </div>
        <div class="vx-col w-4/5">
          <strong
            >Atenção! <br />
            Confira seu saldo e faça todas as transferências antes de fechar o
            caixa.</strong
          >
          <br />
          <h4>
            SALDO ATUAL
            {{
              (cashier.final_balance || 0).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              })
            }}
          </h4>
        </div>
      </div>
      <vs-divider />
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button
              color="secondary"
              class="ml-auto"
              @click="popupCloseCashier = false"
              >Cancelar</vs-button
            >
            <vs-button class="ml-4" color="danger" @click="closeCashier"
              >Fechar Caixa</vs-button
            >
          </div>
        </div>
      </div>
    </vs-popup>

    <list-cashiers
      :show="popupListCashiers"
      :date="listCashierDate"
      @cancel="popupListCashiers = false"
      @see="seeCashier"
    />

    <batch-payment
      :show="popupBatchPayment"
      @saved="refreshGrid"
      @cancel="popupBatchPayment = false"
    ></batch-payment>

    <credit-modal
      :show="popupCredit"
      @saved="refreshGrid"
      @cancel="popupCredit = false"
    ></credit-modal>

    <debit-modal
      :show="popupDebit"
      @saved="refreshGrid"
      @cancel="popupDebit = false"
    ></debit-modal>

    <chargeback-modal
      :show="popupChargeback"
      @saved="refreshGrid"
      @cancel="popupChargeback = false"
    ></chargeback-modal>

    <!-- SE A EMPRESA NAO USA CX INDIVIDUAL -->
    <vx-card
      v-if="
        !user.company.configuration ||
        !user.company.configuration.financial ||
        !user.company.configuration.financial.cashier
      "
      class="my-cashier-filters mb-4"
    >
      <vs-alert
        class="mt-2"
        color="primary"
        title="Orientações:"
        :active="true"
        icon-pack="feather"
      >
        <p>As configurações indicam que NÃO se utilize caixas individuais.</p>
        <p>
          Para utilizar caixas individuais altere as configurações do sistema.
        </p>
      </vs-alert>
    </vx-card>

    <!-- SE A EMPRESA USA CX INDIVIDUAL -->
    <div
      v-else-if="
        user.company.configuration &&
        user.company.configuration.financial &&
        user.company.configuration.financial.cashier
      "
    >
      <!-- SE O USUÁRIO NAO TEM ACESSO A CX INDIVIDUAL -->
      <vx-card v-if="!showMyCashier" class="general-cash-filters mb-4">
        <vs-alert
          class="mt-2"
          color="primary"
          title="Orientações:"
          :active="!showGeneralCash"
          icon-pack="feather"
        >
          <p>Você não tem permissão para acessar o Caixa Individual.</p>
          <p>
            Peça para seu administrador alterar as configurações e liberar seu
            acesso caso necessário.
          </p>
        </vs-alert>
      </vx-card>

      <!-- SE O USUÁRIO TEM ACESSO A CX INDIVIDUAL -->
      <div v-else>
        <div class="vx-row" style="margin-bottom: -15px">
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="ArrowUpIcon"
              icon-right
              :statistic="
                totalIn.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                })
              "
              statisticTitle="Entrada"
              color="success"
            />
          </div>

          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="ArrowDownIcon"
              icon-right
              :statistic="
                totalOut.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                })
              "
              statisticTitle="Saída"
              color="danger"
            />
          </div>

          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DollarSignIcon"
              icon-right
              :statistic="
                (cashier.final_balance || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                })
              "
              statisticTitle="Saldo Atual"
              color="warning"
              :text-color="
                (cashier.final_balance || 0) < 0 ? 'danger' : 'empty'
              "
            />
          </div>

          <div
            class="
              vx-col
              cursor-pointer
              w-full
              sm:w-1/2
              md:w-1/2
              lg:w-1/4
              xl:w-1/4
            "
            @click="printReport('PDF')"
          >
            <statistics-card-line
              hideChart
              class="mb-base hover:text-primary"
              icon="PrinterIcon"
              icon-right
              statistic="IMPRIMIR"
              statisticTitle="Relatório"
            />
          </div>
        </div>

        <vx-card ref="filterCard" class="my-cashier-filters mb-4">
          <div class="vx-row flex-wrap items-center justify-end">
            <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
              <div class="flex flex-wrap">
                <vx-tooltip
                  text="Período Neste Caixa"
                  position="top"
                  class="h-5 w-5 ml-0 mb-6"
                >
                  <vs-dropdown
                    vs-custom-content
                    vs-trigger-click
                    class="cursor-pointer hover:text-primary"
                  >
                    <a href.prevent class="flex cursor-pointer">
                      <feather-icon
                        icon="CalendarIcon"
                        svgClasses="h-6 w-6"
                        class="p-2 inline-flex rounded-full"
                        :class="[`text-primary`, 'mb-0']"
                        :style="{ background: `rgba(var(--vs-success),.15)` }"
                      ></feather-icon>
                    </a>
                    <vs-dropdown-menu
                      class="w-60"
                      style="z-index: 99999999999999"
                    >
                      <vs-input
                        @change="debouncedFilterDate"
                        type="date"
                        v-model="payload.searchQuery.firstPeriod"
                        class="w-full"
                        label="Data Inicial"
                      />
                      <vs-input
                        @change="debouncedFilterDate"
                        type="date"
                        v-model="payload.searchQuery.lastPeriod"
                        class="w-full"
                        label="Data Final"
                      />
                      <span class="text-danger text-sm" v-show="periodError"
                        >O período inicial deve ser anterior ao final.</span
                      >
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </vx-tooltip>

                <vx-tooltip
                  text="Histórico de Caixas"
                  position="top"
                  class="h-5 w-5 ml-6"
                >
                  <vs-dropdown
                    vs-custom-content
                    vs-trigger-click
                    class="cursor-pointer hover:text-primary"
                  >
                    <a href.prevent class="flex cursor-pointer">
                      <feather-icon
                        icon="MenuIcon"
                        svgClasses="h-6 w-6"
                        class="p-2 inline-flex rounded-full"
                        :class="[`text-primary`, 'mb-0']"
                        :style="{ background: `rgba(var(--vs-success),.15)` }"
                      ></feather-icon>
                    </a>
                    <vs-dropdown-menu
                      ref="dropMenu"
                      class="w-60"
                      style="z-index: 99999999999999"
                    >
                      <vs-input
                        @change="debouncedDateListCashier"
                        type="date"
                        v-model="listCashierDate"
                        class="w-full"
                        label="Data"
                      />
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </vx-tooltip>
              </div>

              <!-- <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer hover:text-primary">
                    <label class="text-sm opacity-75">Período</label>
                    <a href.prevent class="flex cursor-pointer">
                      <feather-icon icon="CalendarIcon" svgClasses="h-6 w-6" class="p-2 inline-flex rounded-full" :class="[`text-primary`, 'mb-0']"
                        :style="{background: `rgba(var(--vs-success),.15)`}"></feather-icon>
                    </a>
                    <vs-dropdown-menu class="w-60" style="z-index: 99999999999999;">
                          <vs-input @change="debouncedFilterDate" type="date" v-model="payload.searchQuery.firstPeriod" class="w-full" label="Data Inicial" />
                          <vs-input @change="debouncedFilterDate" type="date" v-model="payload.searchQuery.lastPeriod" class="w-full" label="Data Final" />
                          <span class="text-danger text-sm" v-show="periodError">O período inicial deve ser anterior ao final.</span>
                    </vs-dropdown-menu>
                </vs-dropdown> -->
            </div>
            <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
              <vs-button
                v-if="cashier.status === 'OPEN'"
                @click="closeCashierHandle"
                class="w-full"
                type="line"
                color="danger"
                icon="close_fullscreen"
                >Fechar Cx.</vs-button
              >
              <vs-button
                v-else
                @click="openCashier"
                class="w-full"
                type="line"
                color="primary"
                icon="open_in_full"
                >Abrir Caixa</vs-button
              >
            </div>
            <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
              <vs-button
                @click="transferHandle"
                class="w-full m-icon"
                type="line"
                color="primary"
                icon="compare_arrows"
                :disabled="cashier.status === 'CLOSED' || mixinDenies('financial:my_cashier', true)"
                >Transferência</vs-button
              >
            </div>
            <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
              <vs-button
                @click="batchPayment"
                :disabled="cashier.status === 'CLOSED'"
                class="w-full"
                type="line"
                color="primary"
                icon-pack="feather"
                icon="icon-refresh-ccw"
                >Dar Baixa</vs-button
              >
            </div>
            <div class="vx-col md:w-1/6 sm:w-1/2 p-0 w-full">
              <vs-row class="flex w-full">
                <!-- <vs-col vs-w="2" vs-align="flex-start">
                  <vs-button
                    @click="$refs.creditForm.popup = true"
                    :disabled="cashier.status === 'CLOSED'"
                    type="border"
                    icon-pack="feather"
                    icon="icon-plus-circle"
                    class="w-full"
                  />
                </vs-col> -->
                <vs-col class="w-full">
                  <vs-button
                    @click="credit"
                    color="primary"
                    icon-pack="feather"
                    icon="icon-plus-circle"
                    class="w-full"
                    :disabled="cashier.status === 'CLOSED' || mixinDenies('financial:my_cashier:', true)"
                    >Receita</vs-button
                  >
                </vs-col>
              </vs-row>
            </div>
            <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
              <vs-button
                @click="debit"
                class="w-full"
                color="danger"
                icon-pack="feather"
                icon="icon-minus-circle"
                :disabled="cashier.status === 'CLOSED' || mixinDenies('financial:my_cashier:', true)"
                >Despesa</vs-button
              >
            </div>
          </div>
        </vx-card>

        <div class="vx-card p-6">
          <div class="flex flex-wrap items-center">
            <!-- ITEMS PER PAGE -->
            <div class="flex-grow">
              <vs-dropdown vs-trigger-click class="cursor-pointer">
                <div
                  class="
                    p-4
                    border border-solid
                    d-theme-border-grey-light
                    rounded-full
                    d-theme-dark-bg
                    cursor-pointer
                    flex
                    items-center
                    justify-between
                    font-medium
                  "
                >
                  <span class="mr-2"
                    >{{
                      currentPage * paginationPageSize -
                      (paginationPageSize - 1)
                    }}
                    -
                    {{
                      allData.total - currentPage * paginationPageSize > 0
                        ? currentPage * paginationPageSize
                        : allData.total
                    }}
                    de {{ allData.total }}</span
                  >
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
                <vs-dropdown-menu>
                  <vs-dropdown-item @click="paginationSetPageSize(10)">
                    <span>10</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="paginationSetPageSize(20)">
                    <span>20</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="paginationSetPageSize(30)">
                    <span>30</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="paginationSetPageSize(50)">
                    <span>50</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
              <h3
                class="
                  inline-flex
                  sm:ml-6
                  ml-2
                  sm:w-auto
                  w-full
                  sm:order-normal
                  order-2
                  sm:mt-0
                  mt-4
                  ml-auto
                "
              >
                Período: {{ period }}
              </h3>
            </div>
            <vs-button
              color="primary"
              @click="popupCashierReport.active = true"
              type="flat"
              >Relatório</vs-button
            >
            <span
              :class="[
                (cashier.opening_balance || 0) < 0 ? 'text-danger' : '',
                'ml-auto mr-0 mt-0 font-bold'
              ]"
            >
              Saldo Inicial:
              {{
                (cashier.opening_balance || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                })
              }}
            </span>

            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <!-- <vs-input class="sm:mr-4 mr-0 sm:w-1/3 w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Busca rápida por responsável..." /> -->
            <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->

            <!-- ACTION - DROPDOWN -->
            <!-- <vs-dropdown vs-trigger-click class="cursor-pointer">

                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Ações</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Deletar</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Arquivo</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Imprimir</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>CSV</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown> -->
          </div>
          <!-- AgGrid Table -->
          <ag-grid-vue
            ref="agGridTable"
            :components="components"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            class="ag-theme-material w-100 my-4 ag-grid-table"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            :overlayNoRowsTemplate="noRowsTemplate"
            :localeText="localeText"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :cacheBlockSize="40"
            :suppressPaginationPanel="true"
            :enableRtl="$vs.rtl"
            rowClass="rowClass"
            :rowClassRules="rowClassRules"
          >
          </ag-grid-vue>

          <vs-pagination :total="totalPages" :max="10" v-model="currentPage" />
        </div>
      </div>
    </div>
    <credit-form ref="creditForm" />
  </div>
</template>

<script>
import SigPopup from '@/components/sig/SigPopup'
import CashierReportForm from '@/components/cashier/CashierReportForm'
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import _ from 'lodash'
import { localePt_Br } from '../../../../assets/agGridTranslate/locale.pt_Br'
import CreditForm from '@/components/cashier/credit/CreditForm'
import FormTransfer from '@/components/financial/transfer/FormTransfer'

// Cell Renderer
import CellRendererDateTime from './cell-renderer/CellRendererDateTime.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'

// import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
const StatisticsCardLine = () => import(
    /* webpackChunkName: "StatisticsCardLine" */ '@/components/statistics-cards/StatisticsCardLine.vue'
  )
const BatchPayment = () => import(/* webpackChunkName: "BatchPayment" */ './BatchPayment.vue')
const ListCashiers = () => import(/* webpackChunkName: "ListCashiers" */ './ListCashiers.vue')
const CreditModal = () => import(/* webpackChunkName: "CreditModal" */ './CreditModal.vue')
const DebitModal = () => import(/* webpackChunkName: "DebitModal" */ './DebitModal.vue')
const ChargebackModal = () => import(/* webpackChunkName: "ChargebackModal" */ './ChargebackModal.vue')
const dialogSig = () => import('@/components/dialog/dialogSig.vue')
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
import { CurrencyInput } from 'vue-currency-input'
import moment from 'moment'
import axios from '@/axios.js'

// Store Module
import moduleMyCashier from '@/store/my-cashier/moduleMyCashier.js'
import moduleAccount from '@/store/account/moduleAccount.js'

import PaginationMixin from '@/mixins/pagination'

export default {
  mixins: [PaginationMixin],
  components: {
    SigPopup,
    CashierReportForm,
    AgGridVue,
    FormTransfer,

    // Cell Renderer
    CellRendererDateTime,
    CellRendererActions,
    StatisticsCardLine,
    BatchPayment,
    ListCashiers,
    CreditModal,
    CreditForm,
    DebitModal,
    ChargebackModal,
    dialogSig,
    vSelect,
    CurrencyInput
  },
  data () {
    return {
      popupCashierReport: {
        active: false,
        title: 'Relatório de Caixa'
      },
      showMyCashier: true,

      popupGenerateReceiptConfirm: false,
      printReceiptData: null,
      printReceiptNode: null,

      showPdfTitle: 'PDF',
      srcPdf: '',
      popupShowPdf: false,

      popupCloseCashier: false,
      popupTransfer: false,
      transfer_value: 0,
      account_to: null,
      account_from: null,
      accountsFromOptions: [],
      accountsToOptions: [],

      popupBatchPayment: false,
      popupCredit: false,
      popupDebit: false,
      popupChargeback: false,
      popupListCashiers: false,
      totalIn: 0,
      totalOut: 0,
      totalReceivable: 0,
      totalPayable: 0,
      periodError: false,
      saldoAnterior: 0,
      rowClassRules: {
        'row-danger': (params) => {
          return params.data && params.data.direction === 'OUT'
        }
      },
      localeText: null,
      // Filter Options
      // roleFilter: { label: 'Todos', value: 'all' },
      // roleOptions: [
      //   { label: 'Todos', value: 'all' },
      //   { label: 'Admin', value: 'admin' },
      //   { label: 'User', value: 'user' },
      //   { label: 'Staff', value: 'staff' }
      // ],

      // statusFilter: { label: 'Todos', value: 'all' },
      // statusOptions: [
      //   { label: 'Todos', value: 'all' },
      //   { label: 'Ativo', value: 'active' },
      //   { label: 'Inativo', value: 'deactivated' },
      //   { label: 'Bloqueado', value: 'blocked' }
      // ],

      // isVerifiedFilter: { label: 'Todos', value: 'all' },
      // isVerifiedOptions: [
      //   { label: 'Todos', value: 'all' },
      //   { label: 'Sim', value: 'yes' },
      //   { label: 'Não', value: 'no' }
      // ],

      // departmentFilter: { label: 'Todos', value: 'all' },
      // departmentOptions: [
      //   { label: 'Todos', value: 'all' },
      //   { label: 'Vendas', value: 'sales' },
      //   { label: 'Desenvolvimento', value: 'development' },
      //   { label: 'Gerência', value: 'management' }
      // ],

      searchQuery: '',

      // AgGrid
      gridApi: null,
      dataSource: {},
      gridOptions: {
        rowModelType: 'infinite'
      },
      overlayLoadingTemplate: 'Consultando dados...',
      noRowsTemplate: 'Nenhum dado foi encontrado neste período.',
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        autoHeight: true,
        wrapText: true
      },
      columnDefs: [
        // {
        //   headerName: 'CÓDIGO',
        //   field: 'id',
        //   width: 140,
        //   floatingFilter:true,
        //   filter: 'agNumberColumnFilter',
        //   filterParams: {
        //     suppressAndOrCondition: true,
        //     filterOptions: ['startsWith'],
        //     defaultOption: 'startsWith',
        //     debounceMs: 800
        //   }
        //   // checkboxSelection: true,
        //   // headerCheckboxSelectionFilteredOnly: true,
        //   // headerCheckboxSelection: true
        // },
        {
          headerName: 'Data',
          field: 'created_at',
          width: 135,
          // floatingFilter:true,
          // filter: 'agDateColumnFilter',
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['equals', 'inRange'],
          //   defaultOption: 'equals',
          //   debounceMs: 800,
          // comparator: (filterLocalDateAtMidnight, cellValue) => {
          //   const dateAsString = cellValue
          //   if (dateAsString === null) return -1
          //   const dateParts = dateAsString.split('/')
          //   const cellDate = new Date(
          //     Number(dateParts[2]),
          //     Number(dateParts[1]) - 1,
          //     Number(dateParts[0])
          //   )

          //   if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          //     return 0
          //   }

          //   if (cellDate < filterLocalDateAtMidnight) {
          //     return -1
          //   }

          //   if (cellDate > filterLocalDateAtMidnight) {
          //     return 1
          //   }
          // },
          //   browserDatePicker: true,
          //   minValidYear: 2000
          // },
          cellRendererFramework: 'CellRendererDateTime'
        },
        {
          headerName: 'Descrição',
          field: 'observation',
          minWidth: 400,
          cellClass: 'my-class',
          valueFormatter: (params) => {
            if (params.data) {
              const services =
                params.data.transaction &&
                params.data.transaction.servicePackages
                  ? params.data.transaction.servicePackages
                    .map((e) => ` ${e.name} ${e.category_cnh || ''}`)
                    .flat()
                  : ''
              if (
                params.data.type !== 'CHARGEBACK' &&
                params.data.transaction
              ) {
                return (
                  (params.data.student
                    ? `${params.data.student.name} | `
                    : '') +
                  (params.data.transaction.observation ||
                    'Nenhuma descrição.') +
                  services
                )
              } else if (params.data.type === 'CHARGEBACK') {
                return `ESTORNO ${
                  params.data.transaction.type === 'DEBIT'
                    ? ' DE DÉBITO | '
                    : ' DE CRÉDITO | '
                } ${
                  params.data.student ? `${params.data.student.name} | ` : ''
                } ${
                  params.data.transaction.observation || 'Nenhuma descrição.'
                } ${services}`
              }
            }
          },
          sortable: false

          // data.transaction && data.type !== 'CHARGEBACK' ?
          //     (data.student ? data.student.name +' | ' : '') + (data.transaction.observation ? data.transaction.observation : 'NENHUMA DESCRIÇÃO.') :
          // data.type === 'CHARGEBACK' ?
          //     'ESTORNO ' + (data.transaction.type == 'DEBIT' ? ' DE DÉBITO | ': ' DE CRÉDITO | ' ) + (data.student ? data.student.name +' | ' : '') + (data.transaction.observation ? data.transaction.observation : 'Nenhuma descrição.') :
          //     ''

          // floatingFilter:true,
          // filter: 'agNumberColumnFilter',
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   numberParser: (text) => {
          //     return text === null ? null : text.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,
          //       (regex, a1, a2, a3, a4) => `${a1}.${a2}.${a3}-${a4}`)
          //   },
          //   debounceMs: 800
          // }
        },
        {
          headerName: 'Tipo',
          field: 'type',
          width: 135,
          valueFormatter: (params) => {
            if (params.value) {
              if (params.value === 'TRANSFER') return 'Transferência'
              else if (params.value === 'CHARGEBACK') return 'Estorno'
              else if (params.value) return params.data.type_payment.type_payment
            }
          },
          sortable: false
          // floatingFilter:true,
          // filter: 'agNumberColumnFilter',
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   debounceMs: 800
          // },
        },
        {
          headerName: 'Entrada',
          field: 'value',
          width: 115,
          valueFormatter: (params) => {
            return params.value && params.data.direction === 'IN'
              ? params.value.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              })
              : '--'
          },
          sortable: false
          // floatingFilter:true,
          // filter: true,
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   debounceMs: 800
          // }
        },
        {
          headerName: 'Saída',
          field: 'value',
          width: 115,
          valueFormatter: (params) => {
            return params.value && params.data.direction === 'OUT'
              ? params.value.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              })
              : '--'
          },
          sortable: false
          // floatingFilter:true,
          // filter: true,
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   debounceMs: 800
          // }
        },
        {
          headerName: 'Saldo',
          field: 'balanceLocal',
          width: 115,
          valueFormatter: (params) => {
            return params.data
              ? params.value.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              })
              : '--'
          },
          sortable: false
        },
        {
          headerName: 'Responsável',
          field: 'user',
          width: 200,
          valueFormatter: (params) => {
            return params.value ? params.value.name : ''
          },
          sortable: false
        },
        {
          headerName: 'Ações',
          field: 'actions',
          width: 90,
          sortable: false,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            printReceipt: this.printReceipt, // usado para passar parametros para dentro o componente renderizado no ag grid
            chargeback: this.chargeback // usado para passar parametros para dentro o componente renderizado no ag grid
          }
        }
      ],

      components: {
        CellRendererDateTime,
        CellRendererActions
      },

      payload: {
        id: null,
        offset: 0,
        limit: 40,
        searchQuery: {
          firstPeriod: '',
          lastPeriod: '',
          responsible: ''
        },
        params: null,
        consultApi: true
      },
      listCashierDate: ''
    }
  },
  watch: {
    // roleFilter (obj) {
    //   this.setColumnFilter('id', obj.value)
    // },
    // statusFilter (obj) {
    //   this.setColumnFilter('name', obj.value)
    // },
    // isVerifiedFilter (obj) {
    //   const val = obj.value === 'all' ? 'all' : obj.value === 'yes' ? 'true' : 'false'
    //   this.setColumnFilter('name', val)
    // },
    // departmentFilter (obj) {
    //   this.setColumnFilter('cpf', obj.value)
    // }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    cashier () {
      return this.$store.state.myCashier.cashier
    },
    accounts () {
      return this.$store.getters['account/forSelect']
    },
    period () {
      if (
        this.payload.searchQuery.firstPeriod &&
        this.payload.searchQuery.lastPeriod
      ) {
        return `${moment(this.payload.searchQuery.firstPeriod).format(
          'DD/MM/YYYY'
        )} até ${moment(this.payload.searchQuery.lastPeriod).format(
          'DD/MM/YYYY'
        )}`
      }
      return `${moment().format('DD/MM/YYYY')} até ${moment().format(
        'DD/MM/YYYY'
      )}`
    },
    allData () {
      const data = this.$store.state.myCashier
        ? this.$store.state.myCashier.myCashierTransactions
        : null
      return JSON.parse(JSON.stringify(data))
    }
  },
  methods: {
    seeCashier (cashier) {
      //passo id para ver um cx específico
      this.payload.id = cashier.id
      this.refreshGrid()
      this.payload.searchQuery.firstPeriod = cashier.opening_date
      this.payload.searchQuery.lastPeriod = cashier.closed_date
    },
    openCashier () {
      if (this.cashier.status === 'OPEN') {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'O caixa já está aberto.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      } else {
        this.$vs.loading()
        this.$store
          .dispatch('myCashier/openCashier')
          .then(() => {
            this.$store.dispatch('updateUserInfo', { cashier: 1 })
            this.refreshGrid()
            this.$vs.loading.close()
            this.$vs.notify({
              time: 5000,
              title: 'SUCESSO',
              text: 'Caixa Aberto.',
              color: 'success',
              iconPack: 'feather',
              icon: 'icon-check'
            })
          })
          .catch((error) => {
            this.$vs.loading.close()
            console.error(error)
            if (error.response.status === 412) {
              this.$vs.notify({
                time: 5000,
                title: 'AVISO',
                text: error.response.data.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                time: 5000,
                title: 'Erro',
                text: error.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            }
          })
      }
    },

    async transferHandle () {
      this.transfer_value = 0
      this.account_to = null
      this.account_from = null

      if (this.cashier.status === 'CLOSED') {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'O caixa está fechado.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      } else {
        try {
          const response = await axios.post(
            `${process.env.VUE_APP_API_URL}/cashier/get/criteria`,
            { status: 'OPEN', notMe: false }
          )
          //INSIRO TODOS OS CAIXAS ABERTOS "PARA"
          this.accountsToOptions = JSON.parse(JSON.stringify(this.accounts))
          for (const k in response.data) {
            this.accountsToOptions.push({
              value: response.data[k],
              label: `CAIXA DE ${response.data[k].user.name}`
            })
          }

          //INSIRO O MEU CAIXA NAS OPÇÕES "DE"
          this.accountsFromOptions = JSON.parse(JSON.stringify(this.accounts))
          this.accountsFromOptions.push({
            value: this.cashier,
            label: `CAIXA DE ${this.user.name}`
          })
          this.popupTransfer = true
        } catch (error) {
          console.log(error)
        }
      }
    },
    async transfer () {
      let message = false
      if (this.transfer_value <= 0) message = 'Defina o valor a ser transferido!'
      else if (!this.account_from || !this.account_to) message = 'Defina a conta origem e destino!'
      else if (_.isEqual(this.account_to, this.account_from)) message = 'Escolha origem e destino diferentes!'
      else if (
        !_.has(this.account_from, 'opening_date') &&
        !_.has(this.account_to, 'opening_date')
      ) message = 'A origem ou o destino deve ser seu caixa!'
      else if (
        this.account_from.final_balance &&
        this.account_from.final_balance < this.transfer_value
      ) message = 'Não há saldo suficiente no caixa de origem!'
      else if (
        _.has(this.account_from, 'balance') &&
        !_.isEqual(this.account_to, this.cashier)
      ) message =
          'Você não pode transferir valores de uma conta para um caixa que não seja o seu!'
      if (message) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'top-center'
        })
      } else {
        try {
          this.$vs.loading()
          const response = await axios.post(
            `${process.env.VUE_APP_API_URL}/cashier/transfer`,
            {
              from: this.account_from,
              to: this.account_to,
              transfer_value: this.transfer_value
            }
          )
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Valor Transferido.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
          this.$store.commit('myCashier/SET_CASHIER', response.data) //atualizo o cx para ter saldo e etc iguais no fron e back end
          this.refreshGrid()
          this.$vs.loading.close()
          this.popupTransfer = false
        } catch (error) {
          this.$vs.loading.close()
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        }
      }
    },

    closeCashierHandle () {
      if (this.cashier.status === 'CLOSED') {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'O caixa já está fechado.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      } else {
        this.popupCloseCashier = true
      }
    },
    closeCashier () {
      this.$vs.loading()
      this.$store
        .dispatch('myCashier/closeCashier')
        .then(() => {
          this.$store.dispatch('updateUserInfo', { cashier: 0 })
          this.popupCloseCashier = false
          this.$vs.loading.close()
          this.printReport('PDF')
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Caixa Fechado.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch((error) => {
          this.$vs.loading.close()
          console.error(error)
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        })
    },
    formatDateTime (dateTime) {
      if (!dateTime) return ''
      const [YYYY, MM, DD] = dateTime.slice(0, 10).split('-')
      const [H, m, s] = dateTime.slice(11, 19).split(':')
      return `${DD}/${MM}/${YYYY} ${H}:${m}:${s}`
    },

    // BAIXA O ARQUIVO EXCEL OU WORD
    makeBlobDoc (blob, filename) {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${filename || 'Relatório'}.xlsx`
      link.click()
    },
    async printReport (type) {
      this.$vs.loading()
      this.showPdfTitle = `Posição de Caixa [${this.formatDateTime(
        this.cashier.opening_date
      )} - ${this.formatDateTime(this.cashier.closed_date) || 'Aberto'}]`
      const resp = await this.$http.post(
        `${process.env.VUE_APP_API_URL}/cashier/report`,
        {
          name: `Posição de Caixa [${this.formatDateTime(
            this.cashier.opening_date
          )} - ${this.formatDateTime(this.cashier.closed_date) || 'Aberto'}]`,
          file_name: 'Fechamento de Caixa',
          cashier_id: this.cashier.id,
          format: 'pdf_paisagem'
        },
        { responseType: type === 'EXCEL' ? 'arraybuffer' : null }
      )

      if (type === 'PDF') {
        this.srcPdf = `data:application/pdf;base64,${resp.data}`
        this.popupShowPdf = true
      } else if (type === 'EXCEL') {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        this.makeBlobDoc(blob, this.showPdfTitle)
      }
      this.$nextTick(() => {
        this.$vs.loading.close()
      })
    },

    // APENAS PARA TESTES E USO FUTURO. EXEMPLO DE COMO REDIMENSIONAR LINHAS
    // i () {
    //   this.gridApi.forEachNode((rowNode) => {
    //     console.log(rowNode)
    //     rowNode.setRowHeight(100)
    //   })
    // },
    getSearchResult (searchVal) {
      this.payload.searchQuery.responsible = searchVal
      // this.saldoAnterior = 0
      this.gridApi.setDatasource(this.dataSource)
    },
    // setColumnFilter (column, val) {
    //   const filter = this.gridApi.getFilterInstance(column)
    //   let modelObj = null

    //   if (val !== 'all') {
    //     modelObj = { type: 'equals', filter: val }
    //   }

    //   filter.setModel(modelObj)
    //   this.gridApi.onFilterChanged()
    // },
    // resetColFilters () {
    // Reset Grid Filter
    // this.gridApi.setFilterModel(null)
    // this.gridApi.onFilterChanged()

    // Reset Filter Options
    // this.roleFilter = this.statusFilter = this.isVerifiedFilter = this.departmentFilter = { label: 'Todos', value: 'all' }

    // this.$refs.filterCard.removeRefreshAnimation()
    // },
    // updateSearchQuery (val) {
    //   this.debouncedGetSearchResult(val)
    //   // this.gridApi.setQuickFilter(val)
    // },
    batchPayment () {
      this.popupBatchPayment = true
    },
    credit () {
      this.popupCredit = true
    },
    debit () {
      this.popupDebit = true
    },
    refreshGrid () {
      this.gridApi.setDatasource(this.dataSource)
    },
    filterDate () {
      if (
        moment(this.payload.searchQuery.firstPeriod).year() > 1000 &&
        moment(this.payload.searchQuery.lastPeriod).year() > 1000
      ) {
        const difDays = moment(this.payload.searchQuery.lastPeriod).diff(
          moment(this.payload.searchQuery.firstPeriod),
          'days'
        )
        if (difDays < 0) {
          this.periodError = true
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O período inicial deve ser anterior ao final.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        } else if (difDays > 365 * 5 + 1) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O período não pode ser maior que 5 anos.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }
        this.periodError = false
        // this.saldoAnterior = 0
        this.gridApi.setDatasource(this.dataSource)
      } else if (
        !this.payload.searchQuery.firstPeriod &&
        !this.payload.searchQuery.lastPeriod
      ) {
        this.periodError = false
        this.gridApi.setDatasource(this.dataSource)
      }
    },

    listCashier () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(
        (a) => a.module === 'spa_my_cashier_history_cashiers'
      )
      if (this.user.roles && this.user.roles.rome &&
        !this.user.roles.some((role) => role.name === 'admin') &&
        (!mod || !mod.pivot.permissions.includes('read'))
      ) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      if (moment(this.listCashierDate).year() > 1000) {
        this.$refs.dropMenu.dropdownVisible = false
        this.popupListCashiers = true
      }
    },

    onGridReady () {
      const self = this
      this.dataSource = {
        rowCount: null,
        async getRows (params) {
          self.payload.offset = params.startRow
          self.payload.params = params

          if (self.payload.consultApi) await self.getAllData()
          self.payload.consultApi = true //esta linha faz com que a próxima chamada sempre seja feita para a API

          let lastRow = -1
          if (self.allData.total <= params.endRow) {
            lastRow = self.allData.total
          }
          //CALCULA O SALDO
          if (self.currentPage === 1) self.saldoAnterior = self.cashier.opening_balance
          self.allData.data.map((a) => {
            if (a.direction === 'IN') {
              a.balanceLocal = self.saldoAnterior + a.value
              self.saldoAnterior = self.saldoAnterior + a.value
            } else if (a.direction === 'OUT') {
              a.balanceLocal = self.saldoAnterior - a.value
              self.saldoAnterior = self.saldoAnterior - a.value
            }
          })
          if (self.currentPage > 1) self.saldoAnterior =
              self.allData.data[self.allData.data.length - 1].balanceLocal
          self.totalIn = self.allData.totalIn
          self.totalOut = self.allData.totalOut
          self.totalReceivable = self.allData.totalReceivable
          self.totalPayable = self.allData.totalPayable
          params.successCallback(self.allData.data, lastRow)
        }
      }
      this.gridApi.setDatasource(this.dataSource)
    },

    async getAllData () {
      this.gridApi.showLoadingOverlay()
      const data = await this.$store
        .dispatch('myCashier/fetchFilter', this.payload)
        .catch((err) => {
          console.error(err)
        })
      this.gridApi.hideOverlay()
      if (!data.data.total) {
        this.gridApi.showNoRowsOverlay()
      }
      return JSON.parse(JSON.stringify(data))
    },

    printReceipt (data, node) {
      if (data.transaction && data.transaction.receipts.length) {
        //possui recibo, imprime 2ª via
        this.generateReceipt(data, node)
      } else if (data.transaction && !data.transaction.receipts.length) {
        //não possui recibo, devo perguntar e se for o caso cria recibo
        this.popupGenerateReceiptConfirm = true
        this.printReceiptData = data
        this.printReceiptNode = node
      }
    },
    generateReceipt (data, node) {
      this.$vs.loading()
      this.popupGenerateReceiptConfirm = false
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/document/pdf/receipt/${data.transaction_id}`
        )
        .then(
          function (response) {
            this.showPdfTitle = 'Recibo'
            this.srcPdf = `data:application/pdf;base64,${response.data.pdf}`
            this.popupShowPdf = true

            /**
             * ALTERANDO GRID SEM REFRESH NAS LINHAS ABAIXO
             */
            data.transaction = response.data.transaction
            const rowNode = this.gridApi.getRowNode(node.id)
            rowNode.setData(data)
            // rowNode.setDataValue('type', 'CHARGEBACK')
            if (data.transaction && !data.transaction.receipts.length) this.$store.commit('myCashier/UPDATE', data)

            this.$vs.loading.close()
          }.bind(this)
        )
        .catch(
          function () {
            this.$vs.loading.close()
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          }.bind(this)
        )
    },
    chargeback (data) {
      this.$store.commit('myCashier/SET', data.transaction)
      this.popupChargeback = true
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    this.localeText = localePt_Br
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        '.ag-header-container'
      )
      header.style.left = `-${String(
        Number(header.style.transform.slice(11, -3)) + 9
      )}px`
    }
  },
  created () {
    this.setGrid('allData')
    // this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)
    this.debouncedFilterDate = _.debounce(this.filterDate, 600)
    this.debouncedDateListCashier = _.debounce(this.listCashier, 800)

    if (!moduleMyCashier.isRegistered) {
      this.$store.registerModule('myCashier', moduleMyCashier)
      moduleMyCashier.isRegistered = true
    }
    // nao lembro porque comentei
    // this.$store.dispatch('myCashier/setCashier')
    //   .then(() => {})
    //   .catch((err) => { console.error(err) })

    if (!moduleAccount.isRegistered) {
      // this.$store.registerModule('account', moduleAccount)
      moduleAccount.isRegistered = true
    }
    this.$store
      .dispatch('account/fetchAll')
      .then(() => {})
      .catch((err) => {
        console.error(err)
      })

    // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
    const mod = this.user.permissions_user.find(
      (a) => a.module === 'spa_my_cashier'
    )
    if (
      !this.user.roles.some((role) => role.name === 'admin') &&
      (!mod || !mod.pivot.permissions.includes('read'))
    ) {
      this.showMyCashier = false
    }
  }
}
</script>

<style lang="scss">
#page-my-cashier {
  .my-cashier-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
  .vx-card .vx-card__collapsible-content .vx-card__body {
    padding: 1rem !important;
  }
  .ag-floating-filter-button {
    margin-left: 0px !important;
  }
  .my-class {
    line-height: 25px;
  }
  .cell-wrap-text {
    white-space: normal !important;
  }
  .ag-cell-wrap-text {
    word-break: break-word !important;
  }
  .ag-theme-material .ag-cell {
    padding-left: 10px; // 23
    padding-right: 10px; // 23
  }
  .ag-theme-material .ag-header-cell,
  .ag-theme-material .ag-header-group-cell {
    padding-left: 12px;
    padding-right: 12px;
  }
  // .ag-row-position-absolute {
  //   position: inherit;
  // }
  // .ag-row {
  //   margin-bottom: -40px;
  // }
  .row-danger {
    color: red;
  }
  .rowClass {
    font-size: 12px;
  }

  .noOverflow .vs-popup--content {
    overflow: hidden;
  }

  .m-icon .material-icons {
    font-size: 19px !important;
  }
}
.popup80 .vs-popup {
  width: 80% !important;
}

.popup40 .vs-popup {
  width: 40% !important;
}
</style>
