var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "page-my-cashier" } },
    [
      _c(
        "sig-popup",
        {
          model: {
            value: _vm.popupCashierReport,
            callback: function ($$v) {
              _vm.popupCashierReport = $$v
            },
            expression: "popupCashierReport",
          },
        },
        [_c("cashier-report-form")],
        1
      ),
      _c(
        "dialog-sig",
        {
          attrs: {
            show: _vm.popupGenerateReceiptConfirm,
            title: "ALERTA",
            icon: "warning",
            actionButtonText: "Gerar Recibo",
            actionButtonColor: "warning",
          },
          on: {
            action: function ($event) {
              return _vm.generateReceipt(
                _vm.printReceiptData,
                _vm.printReceiptNode
              )
            },
            cancel: function ($event) {
              _vm.popupGenerateReceiptConfirm = false
            },
          },
        },
        [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                "Um novo recibo será criado! <br> Tem certeza que deseja emitir este recibo?"
              ),
            },
          }),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup80 noOverflow",
          attrs: {
            fullscreen: false,
            title: _vm.showPdfTitle,
            active: _vm.popupShowPdf,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowPdf = $event
            },
          },
        },
        [
          _c("object", {
            staticStyle: { height: "600px" },
            attrs: { data: _vm.srcPdf, width: "100%" },
          }),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup40",
          attrs: {
            fullscreen: false,
            title: "Transferência",
            active: _vm.popupTransfer,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupTransfer = $event
            },
          },
        },
        [
          _c("form-transfer", {
            attrs: { "cashier-id": _vm.cashier.id },
            on: {
              saved: function ($event) {
                ;(_vm.popupTransfer = false), _vm.refreshGrid()
              },
            },
          }),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup40",
          attrs: {
            fullscreen: false,
            title: "Fechamento de Caixa",
            active: _vm.popupCloseCashier,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupCloseCashier = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-1/5" },
              [
                _c("vs-icon", {
                  attrs: { icon: "warning", size: "large", color: "warning" },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col w-4/5" }, [
              _c("strong", [
                _vm._v("Atenção! "),
                _c("br"),
                _vm._v(
                  "\n          Confira seu saldo e faça todas as transferências antes de fechar o\n          caixa."
                ),
              ]),
              _c("br"),
              _c("h4", [
                _vm._v(
                  "\n          SALDO ATUAL\n          " +
                    _vm._s(
                      (_vm.cashier.final_balance || 0).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })
                    ) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupCloseCashier = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      attrs: { color: "danger" },
                      on: { click: _vm.closeCashier },
                    },
                    [_vm._v("Fechar Caixa")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("list-cashiers", {
        attrs: { show: _vm.popupListCashiers, date: _vm.listCashierDate },
        on: {
          cancel: function ($event) {
            _vm.popupListCashiers = false
          },
          see: _vm.seeCashier,
        },
      }),
      _c("batch-payment", {
        attrs: { show: _vm.popupBatchPayment },
        on: {
          saved: _vm.refreshGrid,
          cancel: function ($event) {
            _vm.popupBatchPayment = false
          },
        },
      }),
      _c("credit-modal", {
        attrs: { show: _vm.popupCredit },
        on: {
          saved: _vm.refreshGrid,
          cancel: function ($event) {
            _vm.popupCredit = false
          },
        },
      }),
      _c("debit-modal", {
        attrs: { show: _vm.popupDebit },
        on: {
          saved: _vm.refreshGrid,
          cancel: function ($event) {
            _vm.popupDebit = false
          },
        },
      }),
      _c("chargeback-modal", {
        attrs: { show: _vm.popupChargeback },
        on: {
          saved: _vm.refreshGrid,
          cancel: function ($event) {
            _vm.popupChargeback = false
          },
        },
      }),
      !_vm.user.company.configuration ||
      !_vm.user.company.configuration.financial ||
      !_vm.user.company.configuration.financial.cashier
        ? _c(
            "vx-card",
            { staticClass: "my-cashier-filters mb-4" },
            [
              _c(
                "vs-alert",
                {
                  staticClass: "mt-2",
                  attrs: {
                    color: "primary",
                    title: "Orientações:",
                    active: true,
                    "icon-pack": "feather",
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "As configurações indicam que NÃO se utilize caixas individuais."
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "\n        Para utilizar caixas individuais altere as configurações do sistema.\n      "
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm.user.company.configuration &&
          _vm.user.company.configuration.financial &&
          _vm.user.company.configuration.financial.cashier
        ? _c(
            "div",
            [
              !_vm.showMyCashier
                ? _c(
                    "vx-card",
                    { staticClass: "general-cash-filters mb-4" },
                    [
                      _c(
                        "vs-alert",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            color: "primary",
                            title: "Orientações:",
                            active: !_vm.showGeneralCash,
                            "icon-pack": "feather",
                          },
                        },
                        [
                          _c("p", [
                            _vm._v(
                              "Você não tem permissão para acessar o Caixa Individual."
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              "\n          Peça para seu administrador alterar as configurações e liberar seu\n          acesso caso necessário.\n        "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "vx-row",
                          staticStyle: { "margin-bottom": "-15px" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4",
                            },
                            [
                              _c("statistics-card-line", {
                                staticClass: "mb-base",
                                attrs: {
                                  hideChart: "",
                                  icon: "ArrowUpIcon",
                                  "icon-right": "",
                                  statistic: _vm.totalIn.toLocaleString(
                                    "pt-BR",
                                    {
                                      style: "currency",
                                      currency: "BRL",
                                    }
                                  ),
                                  statisticTitle: "Entrada",
                                  color: "success",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4",
                            },
                            [
                              _c("statistics-card-line", {
                                staticClass: "mb-base",
                                attrs: {
                                  hideChart: "",
                                  icon: "ArrowDownIcon",
                                  "icon-right": "",
                                  statistic: _vm.totalOut.toLocaleString(
                                    "pt-BR",
                                    {
                                      style: "currency",
                                      currency: "BRL",
                                    }
                                  ),
                                  statisticTitle: "Saída",
                                  color: "danger",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4",
                            },
                            [
                              _c("statistics-card-line", {
                                staticClass: "mb-base",
                                attrs: {
                                  hideChart: "",
                                  icon: "DollarSignIcon",
                                  "icon-right": "",
                                  statistic: (
                                    _vm.cashier.final_balance || 0
                                  ).toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  }),
                                  statisticTitle: "Saldo Atual",
                                  color: "warning",
                                  "text-color":
                                    (_vm.cashier.final_balance || 0) < 0
                                      ? "danger"
                                      : "empty",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vx-col cursor-pointer w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4",
                              on: {
                                click: function ($event) {
                                  return _vm.printReport("PDF")
                                },
                              },
                            },
                            [
                              _c("statistics-card-line", {
                                staticClass: "mb-base hover:text-primary",
                                attrs: {
                                  hideChart: "",
                                  icon: "PrinterIcon",
                                  "icon-right": "",
                                  statistic: "IMPRIMIR",
                                  statisticTitle: "Relatório",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "vx-card",
                        {
                          ref: "filterCard",
                          staticClass: "my-cashier-filters mb-4",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "vx-row flex-wrap items-center justify-end",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vx-col md:w-1/6 sm:w-1/2 w-full",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex flex-wrap" },
                                    [
                                      _c(
                                        "vx-tooltip",
                                        {
                                          staticClass: "h-5 w-5 ml-0 mb-6",
                                          attrs: {
                                            text: "Período Neste Caixa",
                                            position: "top",
                                          },
                                        },
                                        [
                                          _c(
                                            "vs-dropdown",
                                            {
                                              staticClass:
                                                "cursor-pointer hover:text-primary",
                                              attrs: {
                                                "vs-custom-content": "",
                                                "vs-trigger-click": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "flex cursor-pointer",
                                                  attrs: { "href.prevent": "" },
                                                },
                                                [
                                                  _c("feather-icon", {
                                                    staticClass:
                                                      "p-2 inline-flex rounded-full",
                                                    class: [
                                                      `text-primary`,
                                                      "mb-0",
                                                    ],
                                                    style: {
                                                      background: `rgba(var(--vs-success),.15)`,
                                                    },
                                                    attrs: {
                                                      icon: "CalendarIcon",
                                                      svgClasses: "h-6 w-6",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "vs-dropdown-menu",
                                                {
                                                  staticClass: "w-60",
                                                  staticStyle: {
                                                    "z-index": "99999999999999",
                                                  },
                                                },
                                                [
                                                  _c("vs-input", {
                                                    staticClass: "w-full",
                                                    attrs: {
                                                      type: "date",
                                                      label: "Data Inicial",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.debouncedFilterDate,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.payload.searchQuery
                                                          .firstPeriod,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.payload
                                                            .searchQuery,
                                                          "firstPeriod",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "payload.searchQuery.firstPeriod",
                                                    },
                                                  }),
                                                  _c("vs-input", {
                                                    staticClass: "w-full",
                                                    attrs: {
                                                      type: "date",
                                                      label: "Data Final",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.debouncedFilterDate,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.payload.searchQuery
                                                          .lastPeriod,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.payload
                                                            .searchQuery,
                                                          "lastPeriod",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "payload.searchQuery.lastPeriod",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.periodError,
                                                          expression:
                                                            "periodError",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "text-danger text-sm",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "O período inicial deve ser anterior ao final."
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "vx-tooltip",
                                        {
                                          staticClass: "h-5 w-5 ml-6",
                                          attrs: {
                                            text: "Histórico de Caixas",
                                            position: "top",
                                          },
                                        },
                                        [
                                          _c(
                                            "vs-dropdown",
                                            {
                                              staticClass:
                                                "cursor-pointer hover:text-primary",
                                              attrs: {
                                                "vs-custom-content": "",
                                                "vs-trigger-click": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "flex cursor-pointer",
                                                  attrs: { "href.prevent": "" },
                                                },
                                                [
                                                  _c("feather-icon", {
                                                    staticClass:
                                                      "p-2 inline-flex rounded-full",
                                                    class: [
                                                      `text-primary`,
                                                      "mb-0",
                                                    ],
                                                    style: {
                                                      background: `rgba(var(--vs-success),.15)`,
                                                    },
                                                    attrs: {
                                                      icon: "MenuIcon",
                                                      svgClasses: "h-6 w-6",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "vs-dropdown-menu",
                                                {
                                                  ref: "dropMenu",
                                                  staticClass: "w-60",
                                                  staticStyle: {
                                                    "z-index": "99999999999999",
                                                  },
                                                },
                                                [
                                                  _c("vs-input", {
                                                    staticClass: "w-full",
                                                    attrs: {
                                                      type: "date",
                                                      label: "Data",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.debouncedDateListCashier,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.listCashierDate,
                                                      callback: function ($$v) {
                                                        _vm.listCashierDate =
                                                          $$v
                                                      },
                                                      expression:
                                                        "listCashierDate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vx-col md:w-1/6 sm:w-1/2 w-full",
                                },
                                [
                                  _vm.cashier.status === "OPEN"
                                    ? _c(
                                        "vs-button",
                                        {
                                          staticClass: "w-full",
                                          attrs: {
                                            type: "line",
                                            color: "danger",
                                            icon: "close_fullscreen",
                                          },
                                          on: { click: _vm.closeCashierHandle },
                                        },
                                        [_vm._v("Fechar Cx.")]
                                      )
                                    : _c(
                                        "vs-button",
                                        {
                                          staticClass: "w-full",
                                          attrs: {
                                            type: "line",
                                            color: "primary",
                                            icon: "open_in_full",
                                          },
                                          on: { click: _vm.openCashier },
                                        },
                                        [_vm._v("Abrir Caixa")]
                                      ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vx-col md:w-1/6 sm:w-1/2 w-full",
                                },
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      staticClass: "w-full m-icon",
                                      attrs: {
                                        type: "line",
                                        color: "primary",
                                        icon: "compare_arrows",
                                        disabled:
                                          _vm.cashier.status === "CLOSED" ||
                                          _vm.mixinDenies(
                                            "financial:my_cashier",
                                            true
                                          ),
                                      },
                                      on: { click: _vm.transferHandle },
                                    },
                                    [_vm._v("Transferência")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vx-col md:w-1/6 sm:w-1/2 w-full",
                                },
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      staticClass: "w-full",
                                      attrs: {
                                        disabled:
                                          _vm.cashier.status === "CLOSED",
                                        type: "line",
                                        color: "primary",
                                        "icon-pack": "feather",
                                        icon: "icon-refresh-ccw",
                                      },
                                      on: { click: _vm.batchPayment },
                                    },
                                    [_vm._v("Dar Baixa")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vx-col md:w-1/6 sm:w-1/2 p-0 w-full",
                                },
                                [
                                  _c(
                                    "vs-row",
                                    { staticClass: "flex w-full" },
                                    [
                                      _c(
                                        "vs-col",
                                        { staticClass: "w-full" },
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              staticClass: "w-full",
                                              attrs: {
                                                color: "primary",
                                                "icon-pack": "feather",
                                                icon: "icon-plus-circle",
                                                disabled:
                                                  _vm.cashier.status ===
                                                    "CLOSED" ||
                                                  _vm.mixinDenies(
                                                    "financial:my_cashier:",
                                                    true
                                                  ),
                                              },
                                              on: { click: _vm.credit },
                                            },
                                            [_vm._v("Receita")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vx-col md:w-1/6 sm:w-1/2 w-full",
                                },
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      staticClass: "w-full",
                                      attrs: {
                                        color: "danger",
                                        "icon-pack": "feather",
                                        icon: "icon-minus-circle",
                                        disabled:
                                          _vm.cashier.status === "CLOSED" ||
                                          _vm.mixinDenies(
                                            "financial:my_cashier:",
                                            true
                                          ),
                                      },
                                      on: { click: _vm.debit },
                                    },
                                    [_vm._v("Despesa")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-card p-6" },
                        [
                          _c(
                            "div",
                            { staticClass: "flex flex-wrap items-center" },
                            [
                              _c(
                                "div",
                                { staticClass: "flex-grow" },
                                [
                                  _c(
                                    "vs-dropdown",
                                    {
                                      staticClass: "cursor-pointer",
                                      attrs: { "vs-trigger-click": "" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium",
                                        },
                                        [
                                          _c("span", { staticClass: "mr-2" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.currentPage *
                                                  _vm.paginationPageSize -
                                                  (_vm.paginationPageSize - 1)
                                              ) +
                                                "\n                  -\n                  " +
                                                _vm._s(
                                                  _vm.allData.total -
                                                    _vm.currentPage *
                                                      _vm.paginationPageSize >
                                                    0
                                                    ? _vm.currentPage *
                                                        _vm.paginationPageSize
                                                    : _vm.allData.total
                                                ) +
                                                "\n                  de " +
                                                _vm._s(_vm.allData.total)
                                            ),
                                          ]),
                                          _c("feather-icon", {
                                            attrs: {
                                              icon: "ChevronDownIcon",
                                              svgClasses: "h-4 w-4",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "vs-dropdown-menu",
                                        [
                                          _c(
                                            "vs-dropdown-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.paginationSetPageSize(
                                                    10
                                                  )
                                                },
                                              },
                                            },
                                            [_c("span", [_vm._v("10")])]
                                          ),
                                          _c(
                                            "vs-dropdown-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.paginationSetPageSize(
                                                    20
                                                  )
                                                },
                                              },
                                            },
                                            [_c("span", [_vm._v("20")])]
                                          ),
                                          _c(
                                            "vs-dropdown-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.paginationSetPageSize(
                                                    30
                                                  )
                                                },
                                              },
                                            },
                                            [_c("span", [_vm._v("30")])]
                                          ),
                                          _c(
                                            "vs-dropdown-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.paginationSetPageSize(
                                                    50
                                                  )
                                                },
                                              },
                                            },
                                            [_c("span", [_vm._v("50")])]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "inline-flex sm:ml-6 ml-2 sm:w-auto w-full sm:order-normal order-2 sm:mt-0 mt-4 ml-auto",
                                    },
                                    [
                                      _vm._v(
                                        "\n              Período: " +
                                          _vm._s(_vm.period) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "vs-button",
                                {
                                  attrs: { color: "primary", type: "flat" },
                                  on: {
                                    click: function ($event) {
                                      _vm.popupCashierReport.active = true
                                    },
                                  },
                                },
                                [_vm._v("Relatório")]
                              ),
                              _c(
                                "span",
                                {
                                  class: [
                                    (_vm.cashier.opening_balance || 0) < 0
                                      ? "text-danger"
                                      : "",
                                    "ml-auto mr-0 mt-0 font-bold",
                                  ],
                                },
                                [
                                  _vm._v(
                                    "\n            Saldo Inicial:\n            " +
                                      _vm._s(
                                        (
                                          _vm.cashier.opening_balance || 0
                                        ).toLocaleString("pt-BR", {
                                          style: "currency",
                                          currency: "BRL",
                                        })
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("ag-grid-vue", {
                            ref: "agGridTable",
                            staticClass:
                              "ag-theme-material w-100 my-4 ag-grid-table",
                            attrs: {
                              components: _vm.components,
                              gridOptions: _vm.gridOptions,
                              columnDefs: _vm.columnDefs,
                              defaultColDef: _vm.defaultColDef,
                              overlayLoadingTemplate:
                                _vm.overlayLoadingTemplate,
                              overlayNoRowsTemplate: _vm.noRowsTemplate,
                              localeText: _vm.localeText,
                              rowSelection: "multiple",
                              colResizeDefault: "shift",
                              animateRows: true,
                              pagination: true,
                              paginationPageSize: _vm.paginationPageSize,
                              cacheBlockSize: 40,
                              suppressPaginationPanel: true,
                              enableRtl: _vm.$vs.rtl,
                              rowClass: "rowClass",
                              rowClassRules: _vm.rowClassRules,
                            },
                            on: { "grid-ready": _vm.onGridReady },
                          }),
                          _c("vs-pagination", {
                            attrs: { total: _vm.totalPages, max: 10 },
                            model: {
                              value: _vm.currentPage,
                              callback: function ($$v) {
                                _vm.currentPage = $$v
                              },
                              expression: "currentPage",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
      _c("credit-form", { ref: "creditForm" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }